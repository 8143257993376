import { Component, Input } from '@angular/core';
import { Const } from "@const/Const";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DialogService } from '@dialogs/dialog.service';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { MasterData } from '@services/master.data';
import { AutoReportType } from '@wearewarp/types';
import { Utils } from '@services/utils';
import { AutoMessagesSetting } from '@app/admin/clients/auto-message-setting';
import moment from 'moment';

@Component({
  selector: "[client-detail-auto-report-notification]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss"
  ],
})
// Auto Report Notification
export class ClientDetailAutoReportNotification extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    reportType: { label: '', initialValue: WarpConst.AutoReportType.all_lane },
    autoMessages: {label: '', type: "formArray", initialValue: [], childItem: {
      laneType: { label: 'Report Type', initialValue: 'zipcode' },
      lanes: { label: 'Lanes', type: 'formArray', initialValue: [{}], childItem: {
        from: { label: "From City or State", required: false, initialValue: "" },
        to: { label: "To City or State", required: false, initialValue: "" },
        fromText: { label: "", required: false, initialValue: "" },
        toText: { label: "", required: false, initialValue: "" },
      }},
      slackChannelIds: { label: 'Slack', type: 'formArray', initialValue: [], childItem: {
        channelId: { label: 'Channel ID', required: true, placeHolder: 'Channel ID' }
      }},
      recipientTos: { label: 'Email (To)', type: 'formArray', initialValue: [{}], childItem: {
        firstName: {label: 'First Name'},
        lastName: {label: 'Last Name'},
        email: {label: 'Email', required: true, validators: Validators.email},
      }},
      recipientCcs: { label: 'Email (CC)', type: 'formArray', initialValue: [], childItem: {
        firstName: {label: 'First Name'},
        lastName: {label: 'Last Name'},
        email: {label: 'Email', required: true, validators: Validators.email},
      }},
      notificationTypes: { label: 'Select Notification Type', type: 'array', initialValue: [] },
      sentAt: { label: '' },
      sentEvery: { label: '' }
    }}
  };

  public allStates = [];
  public allStatesCode = [];
  pageSize = 5;
  pageIndex = 1;
  messagesOfPage = [];
  public reportTypes = [
    { value: WarpConst.AutoReportType.all_lane, label: 'All Lanes' },
    { value: WarpConst.AutoReportType.specific_lane, label: 'Specific Lanes' }
  ];
  private originModel;

  @Input() set myModel(value) {
    this.allStates = MasterData.getStatesUS();
    for (let item of this.allStates) {
      this.allStatesCode[item.code.toUpperCase()] = item;
    }
    let currentModel = this.model;
    this.model = { ...value };
    this.reportType = this.model.reportType || WarpConst.AutoReportType.all_lane;
    this.originModel = { ...value };
    if(currentModel) this.bindDataModel({ ...value });
  }

  get isSpecificLaneMode(): boolean {
    return this.reportType === WarpConst.AutoReportType.specific_lane;
  }

  get isAllLaneMode(): boolean {
    return this.reportType === WarpConst.AutoReportType.all_lane;
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormValues();
  }

  async initFormValues() {
    this.disableEditing();
    this.getNotificationTypes();
    this.getListLocations();
    this.messagesOfPage = this.dataMessages.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
  }

  protected beforeBindModel(model: any) {
    let _autoMessages = model.autoMessages || [];
    let autoMessages = []
    for(let i in _autoMessages) {
      const { recipients = [], slackChannelIds = [], notificationTypes = [], lanes = [], laneType } = _autoMessages[i];
      const recipientTos = recipients.filter(it => it.type === 'to');
      const recipientCcs = recipients.filter(it => it.type === 'cc');

      if(Utils.isArrayNotEmpty(lanes)) {
        for(let lane of lanes) {
          for (let k of ['fromText', 'toText']) {
            if (typeof lane[k] === 'string') continue;
            const key = k.replace('Text', '');
            lane[k] = laneType === "location" ? `${lane[key]?.name} - ${lane[key]?.city}, ${lane[key]?.state}, ${lane[key]?.zipcode}` : `${lane[key]?.city}, ${lane[key]?.state}, ${lane[key]?.zipcode}`;
          }
        }
      }

      const [hours, minutes] = _autoMessages[i].sentAt ? (_autoMessages[i].sentAt).split(":") : ['09', '00'];
      autoMessages.push({
        lanes: lanes.length ? lanes : [{}],
        recipients,
        slackChannelIds: slackChannelIds.length ? slackChannelIds : [],
        recipientTos: recipientTos.length ? recipientTos : [{}],
        recipientCcs: recipientCcs.length ? recipientCcs : [],
        notificationTypes,
        sentAt: new Date().setHours(hours, minutes, 0, 0),
        sentEvery: _autoMessages[i].sentEvery,
        laneType
      })
    }

    model.autoMessages = autoMessages.length ? autoMessages : [{}];
    return model;
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
  }

  public isLoadingNotificationType: boolean = true;
  public notificationTypes = [];
  private getNotificationTypes() {
    this.isLoadingNotificationType = true;
    this.api.GET(Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/CLIENT_SETTINGS/notification_type_settings`)).subscribe(
      resp => {
        this.isLoadingNotificationType = false;
        const value = resp.value || '[]';
        const settings = JSON.parse(value);
        this.notificationTypes = settings;
      }, err => {
        this.isLoadingNotificationType = false;
        this.showErr(err);
      }
    );
  }

  listLocations = [];
  private getListLocations() {
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter?exposeShortAddr=true`;
    this.api.GET(url).subscribe(
      resp => {
        this.listLocations = (resp.data.list_data || []).filter(it => it.name || it.pickAddr?.zipcode);
      }, err => {
        this.showErr(err);
      }
    );
  }

  getValue(index: number, key: string) {
    const value = this.getItemValue(`autoMessages[${index}].${key}`);
    switch (key) {
      case 'recipientTos': 
        return value.filter(it => it?.email);
      case 'lanes':
        return value.filter(it => it?.from);
      case 'recipientCcs':
      case 'slackChannelIds':
        return value;
      case 'notificationTypes':
        return (value || []).map(it => {
          const item = this.notificationTypes.find(nt => nt.key === it);
          const text = item.label;
          const sentAt = this.getItemValue(`autoMessages[${index}].sentAt`);
          const sentEvery = this.getItemValue(`autoMessages[${index}].sentEvery`);
          if (item.key === 'daily_active_routes' && sentAt) {
            return `${text} - Send at ${moment(sentAt).format('HH:mm')} EST`;
          }
          if (item.key === 'update_eta' && sentEvery) {
            return `${text} - Send every ${sentEvery} minutes`;
          }
          return text;
        });
      default:
        return '';
    }
  }

  addLane() {
    DialogService.openFormDialog1(AutoMessagesSetting, {
      nzComponentParams: {
        headerText: `Add Auto Report Setting`,
        reportType: this.reportType,
        listLocations: this.listLocations,
        notificationTypes: this.notificationTypes,
        model: null,
        onSave: data => {
          this.addItemToFormArray('autoMessages', data);
          this.changePageIndex(this.pageIndex);
        }
      },
      nzClassName: "modal modal-xl",
    });
  }

  editItemSetting(index: number) {
    const item = this.getItemValue(`autoMessages[${index}]`);

    DialogService.openFormDialog1(AutoMessagesSetting, {
      nzComponentParams: {
        headerText: `Auto report notification - Lane ${Number(index) + 1}`,
        reportType: this.reportType,
        listLocations: this.listLocations,
        notificationTypes: this.notificationTypes,
        model: { ...item },
        onSave: data => {
          if (data) this.setItemValue(`autoMessages[${index}]`, data);
        }
      },
      nzClassName: "modal modal-xl",
    });
  }

  cloneItemSetting(index: number) {
    const item = this.getItemValue(`autoMessages[${index}]`);

    DialogService.openFormDialog1(AutoMessagesSetting, {
      nzComponentParams: {
        headerText: `Clone Auto Report Setting`,
        reportType: this.reportType,
        listLocations: this.listLocations,
        notificationTypes: this.notificationTypes,
        model: { ...item, isClone: true },
        onSave: data => {
          this.addItemToFormArray('autoMessages', data);
          this.changePageIndex(this.pageIndex);
        }
      },
      nzClassName: "modal modal-xl",
    });
  }

  removeItemSetting(groupKey, index) {
    this.confirmDeletion({
      message: `Are you sure you want to remove this setting?`,
      fnOk: () => {
        this.removeItemInFormArray(groupKey, index);
        if(index % this.pageSize === 0 && this.pageIndex > 1) {
          this.pageIndex = this.pageIndex - 1;
        }
        this.changePageIndex(this.pageIndex);
      }
    });
  }

  public reportType: AutoReportType = WarpConst.AutoReportType.all_lane;
  onChangeReportType(value: AutoReportType) {
    this.reportType = value;
    const isRequired = value === WarpConst.AutoReportType.specific_lane;
    let childKeys = ['from', 'to'];
    for (let childKey of childKeys) {
      this.formGroupDeclaration.autoMessages.childItem['lanes'].childItem[childKey].required = isRequired;
    }
    let fa = this.getFormArrayAutoMessages();
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      for (let childKey of childKeys) {
        let fc = <FormControl>fg.get(childKey);
        if (fc) {
          if (!isRequired) {
            fc.removeValidators(Validators.required);
          } else {
            fc.addValidators(Validators.required);
          }
          fc.updateValueAndValidity();
        }
      }
    }
  }

  private getFormArrayAutoMessages(): FormArray {
    return <FormArray>this.formInput.get('autoMessages');
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    const data = super.getFormData_JSON(true);
    const autoMessages = [];
    for(let i in data.autoMessages) {
      const { lanes = [], recipientTos = [], recipientCcs = [], laneType, sentEvery = null, sentAt, ...otherProps } = data.autoMessages[i];
      let params: any = {}
      if(data.reportType === WarpConst.AutoReportType.specific_lane && laneType === 'zipcode') {
        params.lanes = lanes.map(lane => {
          const item = {
            from: {
              city: lane?.from?.city,
              state: lane?.from?.state,
              zipcode: lane?.from?.zipcode
            },
            to: {
              city: lane?.to?.city,
              state: lane?.to?.state,
              zipcode: lane?.to?.zipcode
            }
          }
          return item;
        })
      }
      if (data.reportType === WarpConst.AutoReportType.specific_lane && laneType === 'location') {
        params.lanes = lanes.map(lane => {
          const fromLocation = this.listLocations.find(it => it.id === lane?.from?.warehouseId);
          const toLocation = this.listLocations.find(it => it.id === lane?.to?.warehouseId);
          const item = {
            from: {
              warehouseId: fromLocation.id,
              name: fromLocation?.name,
              city: fromLocation?.pickAddr?.city,
              state: fromLocation?.pickAddr?.state,
              zipcode: fromLocation?.pickAddr?.zipcode
            },
            to: {
              warehouseId: toLocation.id,
              name: toLocation?.name,
              city: toLocation?.pickAddr?.city,
              state: toLocation?.pickAddr?.state,
              zipcode: toLocation?.pickAddr?.zipcode
            }
          }
          return item;
        })
      }
      let recipients: any = [];
      if(recipientTos.length) {
        recipients = [...recipients, ...recipientTos.map(it => { return { ...it, type: 'to' }})];
      }
      if(recipientCcs.length) {
        recipients = [...recipients, ...recipientCcs.map(it => { return { ...it, type: 'cc' }})];
      }
      autoMessages.push({
        ...otherProps,
        laneType,
        recipients,
        sentAt: sentAt ? moment(sentAt).format('HH:mm') : null,
        sentEvery,
        ...params
      })
    }
    data.autoMessages = autoMessages;
    return data;
  }

  get dataMessages() {
    return this.getArrayControls('autoMessages');
  }

  changePageIndex(value) {
    this.pageIndex = value;
    this.messagesOfPage = this.dataMessages.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
  }

  getIndex(i: number): number {
    return this.pageSize * (this.pageIndex - 1) + i;
  }
}
